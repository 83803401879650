// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require("packs/cookie_banner")
require("packs/bulma-collapsible")
require("packs/highlight")
require("packs/navbar")
require("packs/flash_message")
require("packs/show_old_versions")
