document.addEventListener('turbolinks:load', () => {
  const $oldVersions = document.getElementsByClassName('is-old-version');
  const $checkbox = document.querySelector('input[name=show-old-versions]')

  var toggleOldVersions = function() {
    for (var i = 0; i < $oldVersions.length; ++i) {
      if ($checkbox.checked) {
        $oldVersions[i].style.display = 'block';
        // enable, so that in Safari the links are clickable
        $oldVersions[i].disabled = false;
      } else {
        $oldVersions[i].style.display = 'none';
        // disable, so that in Safari the links are not clickable. they cannot be hidden with display: none
        $oldVersions[i].disabled = true;
      }
    }
  };

  if ($checkbox) {
    toggleOldVersions();
    $checkbox.addEventListener('change', toggleOldVersions);
  }
});
